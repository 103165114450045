<template>
  <div class="container">
    <h3 class="text-center mt-5 mb-5">Утверждение приказов</h3>
    <Calendar v-model="date" showIcon @click="dateScs" />
    <DataTable :value="docOrders" tableStyle="min-width: 50rem" paginator :rows="10"
               :rowsPerPageOptions="[5, 10, 20, 50]" stripedRows responsiveLayout="scroll">
      <Column field="order_number" header="Номер приказа"></Column>
      <Column header="Дата">
        <template #body="{data}">
          {{ convertTimestampToDate(data.doc_order_date) }}
        </template>
      </Column>
      <Column field="docOrderType.name_ru" header="Тип приказа"></Column>
      <Column field="docOrderSubType.name_ru" header="Подтип приказа"></Column>
      <Column field="order_note_ru" header="Примечание"></Column>
      <Column field="order_reason_ru" header="Основание"></Column>
      <Column header="Студенты">
        <template #body="{data}">
          <div v-if="data.docOrderStudents?.length == 0">
            <router-link
                :to="'/doc-orders-students'+ '?doc_order_id='+ +data.id + '&doc_order_type_id=' + data.doc_order_type_id + '&doc_order_sub_type_id='+ data.doc_order_sub_type_id"
                target="_blank">
              Добавить студентов
            </router-link>
          </div>
          <div v-else>
            <router-link
                :to="'/doc-orders-students'+ '?doc_order_id='+ +data.id + '&doc_order_type_id=' + data.doc_order_type_id + '&doc_order_sub_type_id='+ data.doc_order_sub_type_id"
                target="_blank">
              Список студентов
            </router-link>
          </div>
        </template>
      </Column>
      <Column header="Утвердить">
        <template #body="{data}">
          <div v-if="(data.dean_status === '1' && isDean) || (data.rector_status === '1' && isRector)">
            <Button class="p-button-warning" icon="pi pi-check" disabled/>
          </div>
          <div v-else>
            <Button class="p-button-warning" icon="pi pi-check" @click="confirmApproveDocOrder(data.id)"/>
          </div>
        </template>
      </Column>
    </DataTable>
    <ConfirmDialog></ConfirmDialog>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {convertTimestampToDate} from "@/utils/helpers/convertTimestampToDate";
import {decryptCheckRole} from "@/utils/helpers/decryptedRoles.helpers";

export default {
  name: "StudentOrders",
  data() {
    return {
      date: null,
      isDean: decryptCheckRole('dean'),
      isRector: decryptCheckRole('rector')
    }
  },
  methods: {
    ...mapActions('studentOrders', ['GET_DOC_ORDERS', 'POST_APPROVE_ORDERS']),
    convertTimestampToDate,

    confirmApproveDocOrder(id) {
      this.$confirm.require({
        message: 'Вы уверены что хотите утвердить приказ?',
        header: 'Утверждение приказа',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          await this.POST_APPROVE_ORDERS(id)
          const unixTime = this.date.getTime() / 1000 + this.date.getTimezoneOffset() * -60;
          await this.GET_DOC_ORDERS(unixTime)
        }
      });
    },
  },
  watch: {
    date: async function(){
      const unixTime = this.date.getTime() / 1000 + this.date.getTimezoneOffset() * -60;
      await this.GET_DOC_ORDERS(unixTime)
    }
  },
  computed: {
    ...mapState('studentOrders', ['docOrders']),
  },
  async mounted() {
    this.date = new Date;
    // this.date.setDate(this.date.getDate() - 16);
    this.date.setHours(0, 0, 0, 0);
  }
}
</script>

<style scoped>
</style>
